<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">联通单查看下载</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left">
        <el-tab-pane label="技术联系单" name="a"><Connect></Connect></el-tab-pane>
        <el-tab-pane label="更正通知单" name="b"><Notice></Notice></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import Connect from "@/views/fileupdown/engdown/connect";
import Notice from "@/views/fileupdown/engdown/notice";

export default {
  name: "engdown",
  components: {Header, Connect, Notice},
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
  },
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>