<template>
  <div>
    <div>
      <el-select style="margin-left: 5px;margin-bottom: 20px" v-model="oper" placeholder="请选择标段" @change="getfileurl" size="small">
        <el-option
            v-for="item in bdsts"
            :key="item.bdst"
            :label="item.bdstname"
            :value="item.bdst"
        >
        </el-option>
      </el-select>
    </div>
    <div>
      <vxe-table
          :align="allAlign"
          show-overflow
          auto-resize
          height="350"
          :data="noticeData">
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="filename" width="900" title="文件名称"></vxe-column>
        <vxe-column field="bdst" width="200" title="对应工区"></vxe-column>
        <vxe-column title="操作" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="selfile(row)">查看</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!--    分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus';
export default {
  name: "notice",
  data() {
    return {
      oper: '',
      bdsts: [],
      noticeData: [],
      allAlign: 'center',
      dialogYlFile: false,
      fileurl: '',
      pagenum: 1,
      totals: 0,
      pd: true,
    }
  },
  created() {
    this.axios.get('/other/exzx', (response) =>  {
      this.bdsts = response.obj
    });
    this.axios.get('/fileurl/selurl', (response) => {
      this.totals = response.obj.pages * 10;
      this.noticeData = response.obj.records
    }, {
      filetype: 2,
      pageCount: 1
    })
  },
  computed: {
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_BDST'])
  },
  methods: {
    selfile(row) {
      this.axios.post('/fileurl/savedown', (response) => {
        if (response.obj){
          this.fileurl = row.fileurl;
          window.open(this.fileurl, "_blank");
        }else {
          ElMessage.error("查看出错，请联系管理员！")
        }
      },{
        fileurlid: row.id * 1,
        bdst: this.GET_BDST,
        username: this.GET_USER
      })
    },
    getfileurl(){
      this.pd = false;
      this.axios.get('/fileurl/selurlbybdst', (response) => {
        this.totals = response.obj.pages * 10;
        this.noticeData = response.obj.records
      }, {
        filetype: 2,
        bdst: this.oper,
        pageCount: 1
      })
    }
  },
  watch: {
    pagenum(){
      if (this.pd){
        this.axios.get('/fileurl/selurl', (response) => {
          this.totals = response.obj.pages * 10;
          this.noticeData = response.obj.records
        }, {
          filetype: 2,
          pageCount: this.pagenum
        })
      }else {
        this.axios.get('/fileurl/selurlbybdst', (response) => {
          this.totals = response.obj.pages * 10;
          this.noticeData = response.obj.records
        }, {
          filetype: 2,
          bdst: this.oper,
          pageCount: this.pagenum
        })
      }

    }
  }
}
</script>

<style scoped>

</style>